import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompetitionManageService } from '../competition-manage.service';
import { MatSnackBar } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DayManageService } from './day-manage.service';
import { FormControl } from '@angular/forms';
import { DialogsService } from '../../dialogs/dialogs.service';
import {messages} from '../../dialogs/messages';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;
const DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY'
    },
};

@Component({
    selector: 'app-day-manage',
    templateUrl: 'day-manage.component.html',
    styleUrls: ['day-manage.component.scss'],
    preserveWhitespaces: false,
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: DATE_FORMATS
        },
    ]
})
export class DayManageComponent implements OnInit {
    constructor(public competitionManageService: CompetitionManageService,
        private activatedRoute: ActivatedRoute,
        public snackBar: MatSnackBar,
        private dateAdapter: DateAdapter<any>,
        private router: Router,
        private dialogService: DialogsService,
        private dayManageService: DayManageService) {
    }

    competitionId: number; // for correctly save days
    date: any = new FormControl(moment()); // for transform date
    dayId = 0; // for get correct day in days array
    thumbnailId: number; // for send to backend
    url: string; // for frontend
    questions: any[];
    maxDate = this.competitionManageService.maxDate;
    minDate = this.competitionManageService.minDate;

    /**
     * Add event on window close & reload
     */
    @HostListener('window:beforeunload')
    blockWindow() {
        return false;
    }

    /**
     * Open confirm dialog
     */
    open(j) {
        this.dialogService
            .confirm(messages.confirmDialog.confirmDelete.title, messages.confirmDialog.confirmDelete.text)
            .subscribe(result => {
                if (result) {
                    this.deleteQuestion(j);
                }
            });
    }

    /**
     * Delete question
     */
    deleteQuestion(j) {
        this.questions.splice(j, 1);
        this.openSnackBar(messages.snackBar.delete.question);
    }

    /**
     * Show messages
     * @param message String Show main message on snackBar
     * @param action String Show close text
     */
    openSnackBar(message: string, action: string = 'close') {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    /**
     * Get thumbnail
     * @return {number}
     */
    getThumbnail(vimeoId): number | any {

        if (!(/\d+/.test(vimeoId))) {
            return this.openSnackBar(messages.snackBar.vimeoId.wrongFormat);
        }

        return this.dayManageService.getThumbnail(vimeoId).then(data => {
            this.thumbnailId = data.thumbnailId;
            this.url = `url(https://i.vimeocdn.com/video/${data.thumbnailId}_312x175.jpg)`;

            this.competitionManageService.days[this.dayId].thumbnail = +data.thumbnailId;
            this.competitionManageService.days[this.dayId].vimeoId = +vimeoId;
        }).catch(() => {
            return this.openSnackBar(messages.snackBar.vimeoId.wrongId);
        });
    }

    /**
     * Add one question
     */
    addNewQuestion() {
        this.competitionManageService.days[this.dayId].questions.push(
            {
                question: '',
                answers: []
            }
        );
    }

    /**
     * Add one answer
     * @param i index in questions array
     */
    addNewAnswer(i) {
        this.questions[i].answers.push(
            {
                answer: '',
                correct: false
            }
        );
    }

    /**
     * Delete one answer
     * @param i index in answers array
     * @param j index in questions array
     */
    deleteAnswer(j, i) {
        this.questions[j].answers.splice(i, 1);
    }

    /**
     * Set date for one day
     * @param event event from view
     */
    setDate(event): void {
        this.date = new FormControl(event.value);
        this.competitionManageService.days[this.dayId].date = +event.value;
    }

    /**
     * Set answer to answers array
     * @param j index in questions array
     * @param q question
     */
    setQuestion(q, j): void {
        this.competitionManageService.days[this.dayId].questions[j].question = q.value;
    }

    /**
     * Set answer to answers array
     * @param i input value
     * @param c checkbox state
     * @param k index in answers array
     * @param j index in questions array
     */
    setAnswer(i, c, k, j): void {
        this.competitionManageService.days[this.dayId].questions[j].answers[k] = {
            correct: c.checked,
            answer: i.value
        };
    }

    /**
     * Check if answers is checked
     */
    checkAnswers(): boolean {
        let i = 0;

        const questions = this.competitionManageService.days[this.dayId].questions,
            questionLength = this.competitionManageService.days[this.dayId].questions.length;

        for (const question of questions) {

            for (const checkbox of question.answers) {
                if (checkbox.correct) {
                    i++;
                }
            }

        }

        return i >= questionLength;

    }

    /**
     * Navigate back to competition
     */
    goBack(): void {

        if (!this.competitionManageService.days[this.dayId].vimeoId) {
            return this.openSnackBar(messages.snackBar.vimeoId.wrongId);
        }

        if (!this.checkAnswers()) {
            return this.openSnackBar(messages.snackBar.days.setCorrectAnswer);
        }

        localStorage.removeItem('day');
        this.router.navigate(['/admin/competition/' + this.competitionId]);
    }

    ngOnInit() {
        /**
         * Get param for competition id
         */
        this.activatedRoute.parent.params.subscribe(param => {
            this.competitionId = param.id;
        });

        /**
         * Get param for day id
         */
        this.activatedRoute.params.subscribe(param => {
            this.dayId = param.id - 1;
        });

        /**
         * Load day data to day manage fields
         */
        this.questions = this.competitionManageService.days[this.dayId].questions;
        this.date = new FormControl(moment(this.competitionManageService.days[this.dayId].date));
        this.url = `url(https://i.vimeocdn.com/video/${this.competitionManageService.days[this.dayId].thumbnail}_312x175.jpg)`;
        this.competitionManageService.touched();
        this.competitionManageService.fix();
    }
}
