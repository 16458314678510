import {Component, OnInit} from '@angular/core';
import {AdminService} from './admin.service';
import {NavigationStart, Router} from '@angular/router';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    preserveWhitespaces: false,
})
export class AdminComponent implements OnInit {

    constructor(public adminService: AdminService,
                private router: Router) {
    }

    showMenu: boolean;

    /**
     * Logout method
     * @returns {Promise<void>}
     */
    logout() {
        return this.adminService.logout()
            .then(result => {
                this.router.navigate(['admin/login']);
            });
    }

    ngOnInit() {
        /**
         * Hide menu when editing competition
         */
        this.showMenu = /(user-statistics|competition-list)/g.test(this.router.url);
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
                this.showMenu = /(user-statistics|competition-list)/g.test(e.url);
            }
        });

    }

}
