import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DayManageService {

    constructor(private http: HttpClient) {
    }

    /**
     * Get thumbnail
     */
    getThumbnail(vimeoId): any {
        return this.http.post('/admin/competition/vimeo', {id: vimeoId}).toPromise();
    }

}
