export const messages = {
    confirmDialog: {
        unsavedData: {
            title: 'Warning!',
            text: 'You have unsaved data. Exit?'
        },
        confirmDelete: {
            title: 'Warning!',
            text: 'All data will be lost'
        }
    },
    snackBar: {
        login: {
            wrongData: 'Wrong login or password'
        },
        unsavedCompetition: 'Please first save competition',
        fillAllFields: 'Please fill all fields',
        vimeoId: {
            wrongFormat: 'Wrong vimeo id format. Must contains only numbers',
            wrongId: 'Wrong vimeo id',
            noId: 'One or more days do not have vimeo video id'
        },
        delete: {
            competition: 'Competition deleted',
            day: 'Day deleted',
            question: 'Question deleted'
        },
        loadFile: {
            wrongFileType: 'Pick another file type',
            wrongFileSize: 'Not more than 1 mb ☝️'
        },
        competition: {
            wrongId: 'There is no competition with such ID. Please add it or enter correct ID'
        },
        days: {
            setCorrectAnswer: 'One question have not set correct answer'
        },
        date: {
            wrongFormat: 'Date have wrong format. It must be DD.MM.YYYY'
        }
    }
};
