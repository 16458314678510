import {Component, HostListener, OnInit} from '@angular/core';
import {CompetitionManageService} from './competition-manage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {MatSnackBar} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {DialogsService} from '../dialogs/dialogs.service';
import {messages} from '../dialogs/messages';

import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;
const DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY'
    },
};

@Component({
    selector: 'app-competition-manage',
    templateUrl: './competition-manage.component.html',
    styleUrls: ['./competition-manage.component.scss'],
    preserveWhitespaces: false,
    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: DATE_FORMATS
        },
    ]
})
export class CompetitionManageComponent implements OnInit {

    constructor(public competitionManageService: CompetitionManageService,
                private activatedRoute: ActivatedRoute,
                private dialogsService: DialogsService,
                private datePipe: DatePipe,
                private dateAdapter: DateAdapter<any>,
                public snackBar: MatSnackBar,
                private router: Router) {
    }

    competition: any = {
        title: '',
        description: '',
        days: [],
        winnerVideoId: null
    };

    competitionId: number;
    isSaved = false; // for check is competition is saved

    /**
     * Background image url
     */
    url: any;
    /**
     * Date start
     */
    _dateStart: any = new FormControl(moment(''));
    /**
     * Date finish
     */
    _dateFinish: any = new FormControl(moment(''));

    /**
     * Min dates for datepicker
     * @type {Date}
     */
    minDateFinish = new Date(2017, 10, 1);
    minDateStart = new Date(2017, 10, 1);

    /**
     * Max dates for datepicker
     * @type {Date}
     */
    maxDateFinish = new Date(2020, 11, 1);
    maxDateStart = new Date(2020, 11, 1);

    /**
     * Add event on window close & reload
     */
    @HostListener('window:beforeunload')
    blockWindow() {
        if (this.competitionManageService.isTouched) {
            return false;
        }
    }

    /**
     * Check winner video id
     */
    checkID(event) {
        if (event.target.value && !(/^\d+$/.test(event.target.value))) {
            return this.openSnackBar(messages.snackBar.vimeoId.wrongFormat);
        }
    }

    /**
     * Show messages
     * @param message String Show main message on snackBar
     * @param action String Show close text
     */
    private openSnackBar(message: string, action: string = 'close') {
        this.snackBar.open(message, action, {
            duration: 3000
        });
    }

    /**
     * Add description to competition
     */
    addDescription(): void {
        this.dialogsService
            .openModal(this.competition.description)
            .subscribe(result => {

                if (!result || !result.save) {
                    return;
                }

                function replace(key, value) {
                    if (!value) {
                        return '';
                    }

                    return value;
                }

                this.competition.description = JSON.stringify(result.data, replace);
                this.competitionManageService.touched();
            });
    }

    /**
     * Set date
     * @param e Event from datepicker input
     * @param b {boolean} If true - start date, else finish date
     */
    setDate(e, b): void {
        if (b) {
            this.competition.dateStart = +e.value;
            this.minDateFinish = new Date(+e.value);
            this.competitionManageService.minDate = new Date(+e.value);
        } else {
            this.competition.dateFinish = +e.value;
            this.maxDateStart = new Date(+e.value);
            this.competitionManageService.maxDate = new Date(+e.value);
        }
    }

    /**
     * Check fields if they are empty
     */
    checkField(): void {
        this.competitionManageService.touched();
    }

    goBack(): void {
        if (this.competitionManageService.isTouched) {
            this.dialogsService
                .confirm(messages.confirmDialog.unsavedData.title, messages.confirmDialog.unsavedData.text)
                .subscribe(result => {
                    if (result) {
                        this.router.navigate(['/admin/competition-list']);
                    }
                });

            return;
        }

        this.router.navigate(['/admin/competition-list']);
    }

    /**
     * Save competition
     * @param title Competition title
     * @param dateStart Competition start date
     * @param dateFinish Competition end date
     * @param visible Competition visibility
     * @param winnerVideoId Winner video (vimeo id)
     */
    saveCompetition(title, dateStart, dateFinish, visible, winnerVideoId): void {
        this.competition.title = title.value;
        this.competition.visible = visible.checked;
        this.competition.days = this.competitionManageService.days;
        this.competition.winnerVideoId = winnerVideoId.value;
        console.log('%c Send competition', 'font-size: 12px; background: #222; color: #bada55;', this.competition);

        /**
         * Check vimeo video id in days before save competition
         */
        for (let i = 0, l = this.competitionManageService.days.length; i < l; i++) {
            if (!this.competitionManageService.days[i].vimeoId) {
                return this.openSnackBar(messages.snackBar.vimeoId.noId);
            }
        }

        /**
         * Check if fields is empty before save competition
         */
        if (
            !title.value
            || !dateStart.value
            || !dateFinish.value
            || !this.competition.preview
            || !this.competition.description
        ) {
            this.openSnackBar(messages.snackBar.fillAllFields);
            return;
        }

        this.competitionManageService.saveCompetition(this.competition).then(data => {
            if (data.status) {
                this.isSaved = true;
                this.competitionManageService.unTouched();
                this.openSnackBar(data.message + ' 👍');
                this.router.navigate([`/admin/competition/${data.competitionId}`]);

                this.competitionManageService.getCompetition(data.competitionId).then(_data => {
                    this.competitionManageService.days = _data.days;
                });
            }
        }).catch(error => {
            console.log('%c Save competition error', 'font-size: 12px; background: red; color: black', error);
            return;
        });
    }

    /**
     * Format date
     * @param date Date from competition object
     * @return {string | null}
     */
    public dateFormat(date) {
        return this.datePipe.transform(date, 'EEE dd.MM.y');
    }

    /**
     * Format data for one day
     */
    public dayDate(date) {
        return this.dateFormat(date);
    }

    /**
     * Add new day
     */
    addNewDay() {
        if (!this.isSaved) {
            return this.openSnackBar(messages.snackBar.unsavedCompetition);
        }

        this.competitionManageService.days.push(
            {
                competitionId: this.competitionId,
                thumbnail: null,
                vimeoId: null,
                date: +moment(),
                questions: [],
            }
        );

        this.competitionManageService.touched();
    }

    /**
     * Delete day
     */
    public deleteDay(i) {
        this.competitionManageService.days.splice(i, 1);
        this.openSnackBar(messages.snackBar.delete.day);

        if (!this.competitionManageService.days.length) {
            this.competitionManageService.unTouched();
        }
    }

    /**
     * Open confirm dialog for delete
     */
    public open(i) {
        this.dialogsService
            .confirm(messages.confirmDialog.confirmDelete.title, messages.confirmDialog.confirmDelete.text)
            .subscribe(result => {
                if (result) {
                    this.deleteDay(i);
                    this.competitionManageService.touched();
                }
            });
    }

    /**
     * Load image to competition
     */
    loadFile(fileInput) {
        const FILE = fileInput.target.files[0];

        /**
         * Check if file exists
         */
        if (!fileInput.target.files) {
            return;
        }

        /**
         * Check file type
         */
        if (!/(jpeg)/.test(FILE.type)) {
            return this.openSnackBar(messages.snackBar.loadFile.wrongFileType);
        }

        /**
         * greater than 1mb
         */
        if ((FILE.size / 1024) > 1000) {
            return this.openSnackBar(messages.snackBar.loadFile.wrongFileSize);
        }

        const READER = new FileReader();

        READER.onload = (event: any) => {
            this.url = `url(${event.target.result})`;
            this.competition.preview = event.target.result;
        };

        READER.readAsDataURL(fileInput.target.files[0]);
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(param => {

            if (!/(add)|\d+/.test(param.id)) {
                this.router.navigate(['admin/competition/add']);
            }

            if (param.id !== 'add') {
                this.competitionId = +param.id;
                this.isSaved = true;
                this.competitionManageService.getCompetition(param.id).then(data => {
                    this.competition = data;
                    // todo create full url on server
                    this.url = `url(admin/image?competitionId=${data.id}&name=${data.preview})`;

                    this._dateStart = new FormControl(moment(data.dateStart));
                    this._dateFinish = new FormControl(moment(data.dateFinish));

                    this.maxDateStart = new Date(data.dateFinish);
                    this.minDateFinish = new Date(data.dateStart);

                    /**
                     * Max & min date for one day
                     * @type {Date}
                     */
                    this.competitionManageService.minDate = new Date(data.dateStart);
                    this.competitionManageService.maxDate = new Date(data.dateFinish - 86400000);

                    if (!this.competitionManageService.isTouched) {
                        this.competitionManageService.days = data.days;
                    }
                }).catch(error => {
                    if (error.status === 400) {
                        this.openSnackBar(messages.snackBar.competition.wrongId);
                        this.router.navigate(['admin/competition/add']);
                    }
                });
            }
        });

        this.competitionManageService.fix();
    }

}
