import {DayManageComponent} from './competition-manage/day-manage/day-manage.component';
import {Routes} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {AdminComponent} from './admin/admin.component';
import {CompetitionListComponent} from './competition-list/competition-list.component';
import {AuthGuardService as AuthGuard} from './guards/auth-guard.service';
import {AuthGuardLoginService as AuthGuardLogin} from './guards/auth-guard-login.service';
import {CompetitionManageComponent} from './competition-manage/competition-manage.component';
import {UserStatisticsComponent} from './user-statistics/user-statistics.component';

export const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'admin/login'
    },
    {
        path: 'admin/login',
        component: LoginComponent,
        canActivate: [AuthGuardLogin]
    },
    {
        path: 'admin',
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                component: AdminComponent,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'competition-list',
                    },
                    {
                        path: 'competition-list',
                        children: [
                            {
                                path: '',
                                component: CompetitionListComponent
                            }
                        ]
                    },
                    {
                        path: 'competition',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: '/admin/competition-list'
                            },
                            {
                                path: ':id',
                                children: [
                                    {
                                        path: '',
                                        component: CompetitionManageComponent
                                    },
                                    {
                                        path: 'day/:id',
                                        component: DayManageComponent
                                    }
                                ]
                            },
                            {
                                path: 'add',
                                component: CompetitionManageComponent
                            }
                        ]
                    },
                    {
                        path: 'user-statistics',
                        children: [
                            {
                                path: '',
                                component: UserStatisticsComponent
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'admin/login'
    }
];
