import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AdminService {

    constructor(public http: HttpClient) {
    }

    /**
     * Logout method
     * @returns {Promise<Object>}
     */
    public logout() {
        return this.http.post('/admin/logout', {})
            .toPromise();
    }

}
