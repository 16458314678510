import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable()
export class CompetitionManageService {

    constructor(private router: Router,
                private http: HttpClient) {
    }

    days: any[] = [];
    isDayAdded = false;
    isTouched = false;
    maxDate: Date;
    minDate: Date;

    unTouched(): void {
        this.isTouched = false;
    }

    touched(): void {
        this.isTouched = true;
    }

    /**
     * Fix block
     */
    fix(): void {
        const manageFields = <HTMLElement>document.querySelector('.manageFields');

        function fixBlock() {
            manageFields.style.cssText = 'position: fixed; top: 70px;';
        }

        function unfixBlock() {
            manageFields.style.cssText = '';
        }

        window.addEventListener('scroll', e => {
            if (document.body.getBoundingClientRect().top <= 24) {
                fixBlock();
            } else {
                unfixBlock();
            }
        });
    }

    /**
     * Get one competition
     * @param id Competition id
     * @return {any}
     */
    getCompetition(id): any {
        return this.http.post(`/admin/competition/${id}`, {})
            .toPromise();
    }

    /**
     * Save competition
     * @param competition Competition object
     * @return {any}
     */
    saveCompetition(competition): any {
        return this.http.put('/admin/competition', {competition})
            .toPromise();
    }

}
