import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-description-dialog',
    templateUrl: './description-dialog.component.html',
    styleUrls: ['./description-dialog.component.scss']
})
export class DescriptionDialogComponent {

    constructor(public dialogRef: MatDialogRef<DescriptionDialogComponent>) {
    }

    /**
     * Description array
     * 0 - Competition title
     * 1 - Paragraph 1
     * 2 - Paragraph 2
     * 3 - Terms of conditions
     */
    public description = {
        en: [],
        ru: [],
        tr: [],
		pl:	[],
		fr: [],
		es: []
    };

}
