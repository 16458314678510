import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserSatisticsService {

    constructor(private http: HttpClient) {
    }

    /**
     * Load competition list
     * @returns {any}
     */
    competitionList(id): any {
        return this.http.post('admin/competition/statistic', {id: id})
            .toPromise();
    }

}
