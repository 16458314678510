import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthGuardLoginService implements CanActivate {

    constructor( public router: Router, private http: HttpClient ) {
    }

    canActivate(): Promise<boolean> {
        return new Promise( ( resolve, reject ) => {
            this.http.post( 'admin/session', {} )
                .toPromise()
                .then( () => {
                    this.router.navigate( [ 'admin' ] );
                    resolve( false );
                } )
                .catch( () => {
                    resolve( true );
                } );
        } );
    }
}
