import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LoginService {

    constructor(private http: HttpClient) {
    }

    sendForm(formObj: Object): Promise<any> {
        return this.http.post('/admin/authenticated', formObj)
            .toPromise();
    }

}
