/**
 * Import material components
 */
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatChipsModule} from '@angular/material/chips';
import {MatSortModule} from '@angular/material/sort';
import {MatTabsModule} from '@angular/material/tabs';

import 'hammerjs';

/**
 * Import main components, services & modules
 */
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ROUTES} from './routes';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginService} from './auth/login/login.service';
import {HttpClientModule} from '@angular/common/http';
import {AdminComponent} from './admin/admin.component';
import {CompetitionListComponent} from './competition-list/competition-list.component';
import {AdminService} from './admin/admin.service';
import {AuthGuardService} from './guards/auth-guard.service';
import {AuthGuardLoginService} from './guards/auth-guard-login.service';
import {CompetitionManageComponent} from './competition-manage/competition-manage.component';
import {CompetitionListService} from './competition-list/competition-list.service';
import {UserStatisticsComponent} from './user-statistics/user-statistics.component';
import {CompetitionManageService} from './competition-manage/competition-manage.service';
import {DayManageComponent} from './competition-manage/day-manage/day-manage.component';
import {DayManageService} from './competition-manage/day-manage/day-manage.service';
import {ConfirmDialogComponent} from './dialogs/confirm-dialog/confirm-dialog.component';
import {DescriptionDialogComponent} from './dialogs/description-dialog/description-dialog.component';
import {DialogsService} from './dialogs/dialogs.service';
import {UserSatisticsService} from './user-statistics/user-satistics.service';

@NgModule({
    entryComponents: [
        ConfirmDialogComponent,
        DescriptionDialogComponent
    ],
    declarations: [
        AppComponent,
        LoginComponent,
        AdminComponent,
        CompetitionListComponent,
        CompetitionManageComponent,
        UserStatisticsComponent,
        DayManageComponent,
        ConfirmDialogComponent,
        DescriptionDialogComponent
    ],
    imports: [
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatButtonModule,
        RouterModule.forRoot(ROUTES),
        HttpClientModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatCardModule,
        MatIconModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatTableModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatToolbarModule,
        MatGridListModule,
        MatChipsModule,
        MatSortModule,
        MatTabsModule
    ],
    providers: [
        LoginService,
        AdminService,
        AuthGuardService,
        AuthGuardLoginService,
        CompetitionListService,
        CompetitionManageService,
        DayManageService,
        UserSatisticsService,
        DialogsService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
