import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {DescriptionDialogComponent} from './description-dialog/description-dialog.component';
import {MatDialogRef, MatDialog} from '@angular/material';

@Injectable()
export class DialogsService {

    constructor(private dialog: MatDialog) {
    }

    /**
     * Confirm dialog on change route
     * @param {string} title Window title
     * @param {string} message Window message
     * @return {Observable<boolean>}
     */
    public confirm(title: string, message: string): Observable<boolean> {
        let dialogRef: MatDialogRef<ConfirmDialogComponent>;
        dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '300px'
        });
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.afterClosed();
    }

    /**
     * Open description modal window
     * @return {Observable<any>}
     */
    public openModal(description): Observable<any> {
        let dialogRef: MatDialogRef<DescriptionDialogComponent>;
        dialogRef = this.dialog.open(DescriptionDialogComponent, {
            width: '700px'
        });

        if (description && description.indexOf('{') === 0) {
            dialogRef.componentInstance.description = JSON.parse(description);
        }

        return dialogRef.afterClosed();
    }
}
