import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';
import { UserSatisticsService } from './user-satistics.service';

export interface Element {
    position: number;
    id: any;
    email: any;
    username: any;
    country: any;
    subscription: any;
    totalQuestions: any;
    totalAnswers: any;
    correctAnswers: any;
    competitionRegisterDate: any;
    subscriptionDate: any;
}

@Component({
    selector: 'app-user-statistics',
    templateUrl: './user-statistics.component.html',
    styleUrls: ['./user-statistics.component.scss']
})
export class UserStatisticsComponent implements OnInit {

    constructor(public userSatisticsService: UserSatisticsService) {
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns = [
        'position',
        'id',
        'email',
        'username',
        'country',
        'subscription',
        'totalQuestions',
        'totalAnswers',
        'correctAnswers',
        'competitionRegisterDate',
        'subscriptionDate',
    ];

    dataSource = new MatTableDataSource<Element>();
    competitionList: any[];
    showTable: boolean;

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    /**
     * Get competition by id
     * @param id {number} Competition id
     */
    getCompetition(id) {
        this.userSatisticsService.competitionList(id).then(data => {

            /**
             * Add position to data array
             */
            data.map((element, index) => {
                element.position = index + 1; // start from 1
                return data;
            });

            this.dataSource = new MatTableDataSource<Element>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });

        this.showTable = true;
    }

    ngOnInit() {
        this.userSatisticsService.competitionList(null).then(data => {
            this.competitionList = data;
        });

        this.showTable = false;
    }

}
