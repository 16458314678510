import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CompetitionListService {

    constructor(private http: HttpClient) {
    }

    /**
     * Load competition list
     * @returns {any}
     */
    competitionList(): any {
        return this.http.post('admin/competitions', {})
            .toPromise();
    }

    /**
     * Delete one competition
     * @param id Competition id
     */
    deleteCompetition(id): any {
        return this.http.delete(`admin/competition/${id}`)
            .toPromise();
    }

}
