import {Component, OnInit} from '@angular/core';
import {CompetitionListService} from './competition-list.service';
import {DatePipe} from '@angular/common';
import {MatSnackBar} from '@angular/material';
import {DialogsService} from '../dialogs/dialogs.service';
import {CompetitionManageService} from '../competition-manage/competition-manage.service';
import {messages} from '../dialogs/messages';

interface Competition {
    dateFinish: number;
    dateStart: number;
    days: any[];
    description: any;
    id: number;
    preview: string;
    title: string;
    visible: number;
    winnerVideoId: number;
}

@Component({
    selector: 'app-competitions',
    templateUrl: './competition-list.component.html',
    styleUrls: ['./competition-list.component.scss'],
    preserveWhitespaces: false,
    providers: [DatePipe]
})
export class CompetitionListComponent implements OnInit {

    constructor(private competitionListService: CompetitionListService,
                private datePipe: DatePipe,
                private competitionManageService: CompetitionManageService,
                private dialogsService: DialogsService,
                private snackBar: MatSnackBar) {
    }

    competitionList: Competition[];

    /**
     * Show messages
     * @param {string} message Show main message on snackBar
     * @param {string} action Show close text
     */
    private openSnackBar(message: string, action: string = 'close') {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    /**
     * Format date
     * @param date Date from competition object
     * @return {string | null}
     */
    private dateFormat(date) {
        return this.datePipe.transform(date, 'dd.MM.y');
    }

    /**
     * Create subtitle for competition
     * @param item Competition object from view
     * @return {string}
     */
    public subTitle(item) {
        return `
                <span class="date">${this.dateFormat(item.dateStart)} - ${this.dateFormat(item.dateFinish)}</span>
                <span class="isVisible">${item.visible ? 'visible' : 'invisible'}</span>
            `;
    }

    /**
     * Open confirm dialog for delete
     */
    open(id, i) {
        this.dialogsService
            .confirm(messages.confirmDialog.confirmDelete.title, messages.confirmDialog.confirmDelete.text)
            .subscribe(result => {
                if (result) {
                    this.deleteCompetition(id, i);
                }
            });
    }

    /**
     * Delete one competition & remove it from competitionList
     */
    public deleteCompetition(id, i) {
        this.competitionListService.deleteCompetition(id);
        this.competitionList.splice(i, 1);
        this.openSnackBar(messages.snackBar.delete.competition);
    }

    ngOnInit() {
        /**
         * Clean data
         */
        this.competitionManageService.days = [];
        this.competitionManageService.unTouched();
        this.competitionListService.competitionList().then(data => {
            this.competitionList = data;
        });
    }

}
