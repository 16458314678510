import {Component} from '@angular/core';
import {LoginService} from './login.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import TweenLite from 'gsap';
import {messages} from '../../dialogs/messages';

interface ErrorsObj {
    login?: string;
    password?: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    preserveWhitespaces: false
})
export class LoginComponent {

    constructor(private loginService: LoginService,
                public snackBar: MatSnackBar,
                private formBuilder: FormBuilder,
                private router: Router) {
        /**
         * Validate form
         */
        this.loginForm = formBuilder.group({
            'login': ['', Validators.compose([
                Validators.required,
                Validators.pattern(/^[a-z0-9]+$/i)
            ])],
            'password': ['', Validators.compose([
                Validators.required,
                Validators.pattern(/^[a-z0-9,.'-]+$/i)
            ])]
        });

        this.loginControl = this.loginForm.controls['login'];
        this.passwordControl = this.loginForm.controls['password'];
    }

    loginControl: AbstractControl;
    passwordControl: AbstractControl;
    loginForm: FormGroup;
    errorsObj: ErrorsObj = {};
    isDarkTheme = false;

    /**
     * Load video background on login page
     */
    private showSuccess(): void {
        const overlay = document.querySelector('.success');
        TweenLite.to(overlay, .5, {
            opacity: 1,
            visibility: 'visible'
        });
    }

    /**
     * Show messages
     * @param {string} message Show main message on snackBar
     * @param {string} action Show close text
     */
    openSnackBar(message, action = 'close') {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    /**
     * Login method
     * @param {Object} formObj form fields
     */
    login(formObj: Object) {

        if (!this.loginForm.valid) {
            return;
        }

        this.loginService.sendForm(formObj).then(data => {
            // if login is incorrect
            if (!data.status) {
                this.openSnackBar(messages.snackBar.login.wrongData);
                return;
            }

            this.showSuccess();
            setTimeout(() => {
                this.router.navigate(['/admin/competition-list']);
            }, 2000);

        }).catch(error => {
            if (error) {
                this.errorsObj = error.error;
            }
        });
    }
}
